import * as React from 'react';

import type { ISanityContactForm } from '../fragments/sanity-contact-form-parts';
import type { ISanityCopyWithImage } from '../fragments/sanity-copy-with-image-parts';
import { ISanityCopyWithCTA } from '../fragments/sanity-copy-with-cta-parts';
import type { ISanityFaqs } from '../fragments/sanity-frequently-asked-questions-parts';
import type { ISanityGoogleMap } from '../fragments/sanity-google-map-parts';
import type { ISanityGroupedModules } from '../fragments/sanity-group-modules-parts';
import type { ISanityHero } from '../fragments/sanity-hero-parts';
import type { ISanityServicesGrid } from '../fragments/sanity-services-grid-parts';
import type { ISanityTileBanners } from '../fragments/sanity-tile-banners-parts';

import { ContactSection } from './modules/contact-section';
import { CopyWithImage } from './modules/copy-with-image';
import { CopyWithCTA } from './modules/copy-with-cta';
import { FAQs } from './modules/faqs';
import { GoogleMap } from './modules/google-map';
import { Hero } from './modules/hero';
import { ServicesGrid } from './modules/services-grid';
import { TileBanners } from './modules/tile-banners';

import { BG_COLOUR_MAP } from '../utils/object-dictionaries';

type TSanityModule =
  | ISanityHero
  | ISanityCopyWithImage
  | ISanityCopyWithCTA
  | ISanityServicesGrid
  | ISanityFaqs
  | ISanityContactForm
  | ISanityGoogleMap
  | ISanityTileBanners
  | ISanityGroupedModules;

interface SanityModulesProps {
  modules: TSanityModule[];
}

function SanityModules({ modules }: SanityModulesProps): JSX.Element {
  return (
    <>
      {modules.map((module) => {
        if (!module) return null;
        return <SanityModule key={module.id} module={module} />;
      })}
    </>
  );
}

interface SanityGroupedModulesProps {
  groupedModules: ISanityGroupedModules;
}

function SanityGroupedModules({
  groupedModules,
}: SanityGroupedModulesProps): JSX.Element {
  return (
    <div className={`${BG_COLOUR_MAP[groupedModules.bgColour]}`}>
      {groupedModules.modules.map((module) => {
        if (!module) return null;
        return <SanityModule key={module.id} module={module} />;
      })}
    </div>
  );
}

interface SanityModuleProps {
  module: TSanityModule;
}

function SanityModule({ module }: SanityModuleProps): JSX.Element | null {
  switch (module._type) {
    case 'hero':
      return <Hero hero={module} />;

    case 'copyWithImage':
      return <CopyWithImage copyWithImage={module} />;

    case 'copyWithCTA':
      return <CopyWithCTA copyWithCTA={module} />;

    case 'servicesGrid':
      return <ServicesGrid servicesGrid={module} />;

    case 'frequentlyAskedQuestions':
      return <FAQs frequentlyAskedQuestions={module} />;

    case 'contactForm':
      return <ContactSection contactSection={module} />;

    case 'googleMap':
      return <GoogleMap googleMap={module} />;

    case 'tileBanners':
      return <TileBanners tileBanners={module} />;

    case 'groupedModules':
      return <SanityGroupedModules groupedModules={module} />;

    default:
      return null;
  }
}

export { SanityModules };
export type { TSanityModule };
