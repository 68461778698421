import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import type { ISanityServicesGrid } from '../../fragments/sanity-services-grid-parts';
import { classNames } from '../../utils/classnames';
import {
  BG_COLOUR_MAP,
  TEXT_COLOUR_MAP,
  ASPECT_RATIO_MAP,
} from '../../utils/object-dictionaries';

const SERVICE_CARDS_MAP = {
  2: 'md:w-1/2',
  3: 'md:w-1/3',
  4: 'md:w-1/4',
  5: 'md:w-1/3',
  6: 'md:w-1/3',
};

const HEADING_COLOUR_MAP = {
  transparent: 'text-tertiary',
  light: 'text-light ',
  primary: 'text-light',
  secondary: 'text-secondary',
  dark: 'text-dark',
  medium: 'text-medium',
};

interface ServicesGridProps {
  servicesGrid: ISanityServicesGrid;
}

function ServicesGrid({ servicesGrid }: ServicesGridProps): JSX.Element {
  return (
    <div className={`${BG_COLOUR_MAP[servicesGrid.colourScheme]}`}>
      <div className="w-full px-4 py-12 mx-auto max-w-prose lg:max-w-screen-2xl sm:px-6 lg:px-8 lg:py-24">
        <h2
          className={`text-4xl font-semibold uppercase text-center ${
            HEADING_COLOUR_MAP[servicesGrid.colourScheme]
          }`}
        >
          {servicesGrid.title}
        </h2>
        <ul className="flex flex-wrap justify-center -mx-4">
          {servicesGrid.services.map((service, index) => (
            <li
              key={index}
              className={classNames(
                'px-4 mt-12 w-full max-w-lg sm:w-1/2',
                servicesGrid.services.length > 0 &&
                  SERVICE_CARDS_MAP[servicesGrid.services.length]
              )}
            >
              <Link
                to={
                  service.page._type === 'homePage'
                    ? '/'
                    : `/${service.page.slug.current}/`
                }
                className="relative block"
              >
                <div
                  className={classNames(
                    'relative overflow-hidden h-0',
                    ASPECT_RATIO_MAP[service.figure.customRatio]
                  )}
                >
                  {service.figure?.asset ? (
                    <div className="absolute inset-0 flex">
                      <GatsbyImage
                        image={service.figure.asset.gatsbyImageData}
                        alt={service.figure.alt ?? ''}
                        className="flex-1"
                      />
                    </div>
                  ) : null}
                </div>
                <h3
                  className={`py-4 text-2xl font-semibold text-center uppercase absolute inset-x-0 bottom-0 ${
                    TEXT_COLOUR_MAP[servicesGrid.colourScheme]
                  }`}
                >
                  {service.title}
                </h3>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export { ServicesGrid };
