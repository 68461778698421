import * as React from 'react';

import type { ISanityTileBanners } from '../../fragments/sanity-tile-banners-parts';
import { classNames } from '../../utils/classnames';
import { BG_COLOUR_MAP } from '../../utils/object-dictionaries';

const TILE_COLOUR_MAP = {
  light: 'bg-light ',
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  dark: 'bg-dark',
  medium: 'bg-medium',
};

interface TileBannersProp {
  tileBanners: ISanityTileBanners;
}

function TileBanners({ tileBanners }: TileBannersProp): JSX.Element {
  return (
    <div className={`${BG_COLOUR_MAP[tileBanners.bgColour]} relative`}>
      <div className="w-full mx-auto lg:max-w-screen-xl sm:px-6 lg:px-8 lg:pt-24">
        <ul className="flex flex-wrap justify-center -mt-36 lg:gap-8 mb-8 lg:mb-12 lg:-mx-8">
          {tileBanners.tiles.map((tile) => (
            <li
              key={tile.id}
              className={classNames(
                tile.title.length > 32 ? 'w-72' : tile.title.length > 11 ? 'w-40' : 'w-28',
                'md:w-96 mx-1 my-1 col-span-2 col-end-1 lg:col-span-1'
              )}
            >
              <div
                className={`text-white text-center uppercase px-4 lg:px-8 min-h-[100px] lg:min-h-[128px] flex flex-col justify-center ${
                  TILE_COLOUR_MAP[tile.bgColour]
                }`}
              >
                <h2 className="font-sans text-xs md:text-xl font-semibold">
                  {tile.title}
                </h2>
                {tile.subtitle && (
                  <p className="text-xs md:text-md">{tile.subtitle}</p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export { TileBanners };
