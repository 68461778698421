import BlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import type { ISanityCopy } from '../../fragments/sanity-copy-parts';
import type { ISanityCopyWithCTA } from '../../fragments/sanity-copy-with-cta-parts';
import type { TColourScheme } from '../../types';
import { classNames } from '../../utils/classnames';
import {
  BG_COLOUR_MAP,
  PROSE_COLOUR_MAP,
  TEXT_COLOUR_MAP,
} from '../../utils/object-dictionaries';
import { ButtonLink } from '../button-link';

interface CopyWithCTAProps {
  copyWithCTA: ISanityCopyWithCTA;
}

function CopyWithCTA({ copyWithCTA }: CopyWithCTAProps): JSX.Element {
  return (
    <div className={`${BG_COLOUR_MAP[copyWithCTA.copy.colourScheme]}`}>
      <div className="w-full px-4 py-12 mx-auto max-w-prose lg:py-24 lg:max-w-screen-2xl sm:px-6 lg:px-8">
        <Copy
          key={copyWithCTA.id}
          copy={copyWithCTA.copy}
          colourScheme={copyWithCTA.copy.colourScheme}
        />
      </div>
    </div>
  );
}

interface CopyProps {
  copy: ISanityCopy;
  colourScheme: TColourScheme;
}

function Copy({ copy, colourScheme }: CopyProps): JSX.Element {
  return (
    <>
      <BlockContent
        renderContainerOnSingleChild
        blocks={copy._rawBody}
        className={classNames(
          'sanity-block prose-lg',
          TEXT_COLOUR_MAP[copy.colourScheme],
          PROSE_COLOUR_MAP[copy.colourScheme]
        )}
        projectId="j3qg7m62"
        dataset="production"
      />
      {copy.ctas
        ? copy.ctas.map((cta) => (
            <p key={cta.id} className="mt-5">
              <ButtonLink link={cta} colourScheme={colourScheme} />
            </p>
          ))
        : null}
    </>
  );
}

export { CopyWithCTA };
export type { ISanityCopyWithCTA };
